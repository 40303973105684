@mixin mad-footer-layout {
    .mad-footer-left {
        float: left;
    }
    .mad-footer-right {
        float: right;
    }
    .mad-footer-content {
        flex: 1;
    }
    //flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    //.mad-footer-inner {
        //flex: 1;
        //display: flex;
        //flex-direction: row;
        //align-items: center;
    //}
}

@mixin mad-footer-navigation {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    min-height: 50px;
    background: #eee;
    z-index: 6;
    display: flex;
    @include mad-footer-layout;
}

@mixin mad-footer-card {
    padding: 1em;
    border-bottom-right-radius: 0.5em;
    border-bottom-left-radius: 0.5em;
    color: #1e1f1f;
    font-weight: 400;
    background-color: white;
    display: block;
    width: 100%;
    position:relative;
    border-top: solid 1px rgba(200,200,200,0.75);
    @include mad-footer-layout;
}

@mixin mad-footer-modal {
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 15px;
    bottom: 0;
    background: none;
    position: relative;
    padding-top: 1em;
    border-top: solid 1px rgba(200,200,200,0.75);
    min-width: 100%;
    min-height: 50px;

    //.mad-footer-inner {
        position: relative;
        padding-top: 1em;
        border-top: solid 1px rgba(200,200,200,0.75);
        min-width: 100%;
        min-height: 50px;
    //}
    @include mad-footer-layout;
}

@mixin mad-footer-toast {
    @include mad-footer-modal;
}
