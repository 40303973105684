@mixin mad-body-navigation {
    flex: 1;
    overflow: auto;
    display: flex;
    //align-items: center;
    flex-direction: column;
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
    position: relative;
    //width: 100%;
    //.mad-body-inner {
        //max-width: 1400px;
        //width: 100%;
        //flex: 1;
        //display: flex;
        //flex-wrap: wrap;
    //}
}

@mixin mad-body-header {
    @include mad-body-navigation;
    padding-top: 0;
    padding-bottom: 0;
    overflow: visible;
    flex-direction:row;
    //.mad-body-inner {
        //max-width: 100%;
        //display: flex;
    //}
}

@mixin mad-body-card {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1.2em;
    padding-right: 1.5em;
}
 
@mixin mad-body-modal {
    padding: 0;
    padding-left: 2em;
    padding-right: 2em;
    display: block;
    text-align: start;
    position:relative;
}

@mixin mad-body-toast {
    @include mad-body-modal;
    padding-left: 0;
}
