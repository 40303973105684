/* You can add global styles to this file, and also import other style files */
body {
    margin: 0;
    font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@import './mixins.scss';

mad-navigation-layout mad-footer {
    @include mad-footer-navigation;
}
mad-navigation-layout mad-body {
    @include mad-body-navigation;
}
mad-footer .mad-footer-content > mad-body, mad-header .mad-header-content > mad-body {
    @include mad-body-header;
}
mad-header, mad-navigation-layout > mad-header {
    @include mad-header-navigation;
}

mad-navigation-layout > mad-header, mad-navigation-layout > mad-footer {
    z-index: 7;
}

mad-card mad-footer {
    @include mad-footer-card;
}
mad-card mad-body {
    @include mad-body-card;
}
mad-card mad-header {
    @include mad-header-card;
    mad-button[type=cancel-icon] button {
        font-size: 28px;
    }
}

.mad-alert mad-footer, .mad-modal mad-footer {
    @include mad-footer-modal;
}
.mad-alert mad-body, .mad-modal mad-body {
    @include mad-body-modal;
}
.mad-alert mad-header, .mad-modal mad-header {
    @include mad-header-modal;
    mad-button[type=cancel-icon] button {
        font-size: 32px;
    }
}

.mad-toast mad-footer {
    @include mad-footer-toast;
}
.mad-toast mad-body {
    @include mad-body-toast;
}
.mad-toast mad-header {
    @include mad-header-toast;
}

mad-button mad-icon {
    @include mad-icon-button;
    color: inherit;
    fill: inherit;
}
//mad-button[type]:not([type=primary]):not([type='sales primary']) mad-icon {
    //color: #00a7b5;
    //svg {
        //fill: #00a7b5 !important;
    //}
//}

mad-avatar mad-icon {
    @include mad-icon-avatar;
}
mad-link mad-icon {
    @include mad-icon-link;
}


.scrollable {
  /* The background-color of the scrollbar cannot be transitioned.
     To work around this, we set and transition the property
     of the wrapper and just set the scrollbar to inherit its
     value. Now, when the the wrapper transitions that property,
     so does the scrollbar. However, if we set a background-color,
     this color shows up in the wrapper as well as the scrollbar.
     Solution: we transition the border-color and add a border-right
     to the scrollbar that is as large as the viewport. */
  border-color: rgba(0, 0, 0, 0.0);
  transition: border-color 0.75s linear;
}

.scrollable:hover {
  border-color: rgba(0, 0, 0, 0.2);
  transition: border-color 0.125s linear;
}

.scrollable::-webkit-scrollbar,
.scrollable::-webkit-scrollbar-thumb,
.scrollable::-webkit-scrollbar-corner {
  border-right-style: inset;
  border-right-width: calc(100vw + 100vh);
  border-color: inherit;
}

.scrollable::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  border-color: transparent;
}

.scrollable::-webkit-scrollbar {
  width: 0.5rem;
}

.scrollable::-webkit-scrollbar-thumb {
  border-radius: 10em;
  border-color: inherit;
}

.scrollable::-webkit-scrollbar-thumb:hover {
  border-color: rgba(0, 0, 0, 0.25);
}

.scrollable::-webkit-scrollbar-thumb:active {
  border-color: rgba(0, 0, 0, 0.3);
}

.spot-typography__heading--level-1,
.spot-typography__heading--level-2,
.spot-typography__heading--level-3,
.spot-typography__heading--level-4,
.spot-typography__heading--level-5,
.spot-typography__heading--level-4,
.spot-typography__heading--level-4,
.spot-typography__heading--level-1,
.spot-typography__heading--level-2,
.spot-typography__heading--level-3,
.spot-typography__heading--level-4,
.spot-typography__heading--level-5,
.spot-typography__text--body,
.spot-typography__text--body,
.spot-typography__text--secondary,
.spot-typography__text--tertiary,
.spot-typography__font-weight--bold,
.spot-long-form-text {
    color: inherit;
}
