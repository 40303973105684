@mixin mad-icon {
    display: inline-flex;
    height: 1em;
    width: 1em;
    i {
        position: relative;
        display: flex;
        color: inherit !important;
    }
    svg {
        display: flex;
        position: relative;
        height: 100% !important;
        width: 100% !important;
        margin: 0 !important;
        fill: inherit !important;
    }
    .icon {
        transition: opacity 400ms, transform 400ms;
        opacity: 0;
        transform: scale(0.5)rotate(180deg);
    }
    .icon.show {
        opacity: 1;
        transform: scale(1);
    }
    .mad-icon-inner {
        position: relative;
        display: flex;
        align-items: center;
    }
    .alt-icon {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transform: scale(0.5);
        transition: opacity 400ms, transform 400ms;
    }
    .alt-icon.show {
        opacity: 1;
        transform: scale(1);
    }
}

@mixin mad-icon-button {
    @include mad-icon;
    justify-content: center;
    svg {
        //width: 18px !important;
        //height: 18px !important;
    }
}

@mixin mad-icon-link {
    @include mad-icon;
    justify-content: center;
}

@mixin mad-icon-avatar {
    align-items:center;
    justify-content:center;
    text-align:center;
    border-radius: 50%;
    background: rgba(0,0,0,0.1);
    padding: 0.2em;
    box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.15);
    @include mad-icon;
}

