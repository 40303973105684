@mixin mad-header {
    &[gray],&.gray {
        background: #F3F4F6;
        color: #419FB1;
        fill: #419FB1;
    }
    &[blue],&.blue {
        background: #334B73;
        color: #89CAD6;
        fill: #89CAD6;
    }
    &[teal],&.teal {
        background: #3B98AB;
        color: white;
        fill: white;
    }
    &[white],&.white {
        background: #FFFFFF;
        border-bottom: solid 1px #3B98AB;
        color: black;
        fill: black;
    }
    & {
        position: -webkit-sticky; /* Safari */
    }
    & {
        position: sticky;
    }

    top: 0;
    transition: background 400ms, opacity 500ms;
    min-height: 50px;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
    display: flex;
    flex-direction: row;
    width: 100%;
    //padding-left: 0.5em;
    //padding-right: 0.5em;
    z-index: 6;
    align-items: center;

    //.mad-header-inner {
        //display: flex;
        //flex: 1;
        //flex-direction: row;
        //align-items: center;
        //width: 100%;
    //}

    .mad-header-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        display: inline-flex;
        flex-wrap: wrap;
        align-items: center;
    }

    .mad-header-left {
        float: left;
        display: inline-flex;
        align-items: center;
    }
    .mad-header-right {
        float: right;
        display: inline-flex;
        align-items: center;
    }
    .mad-header-content {
        flex: 1;
    }

}

@mixin mad-header-navigation {
    @include mad-header;

    .mad-icon-only svg {
        width: 24px !important;
        height: 24px !important;
    }
    .mad-icon-only i {
        font-size: 24px !important;
    }
}

@mixin mad-header-card {
    @include mad-header;

    min-height: auto;
    font-size: 26px;
    line-height: 38px;
    font-weight: 300;
    border-bottom: solid 1px rgba(200, 200, 200, 0.75);
    padding-left: 1em;
    width: auto;
    &[teal],&[blue],&[gray] {
        border: none;
    }
    //.mad-header-inner {
        //max-width: 100%;
    //}
}

@mixin mad-header-modal {
    @include mad-header;

    line-height: 50px;
    font-size: 26px;
    font-weight: 300;
    padding-left: 1.15em;
    padding-right: 0.2em;
    //.mad-header-inner {
        //max-width: 100%;
    //}
}

@mixin mad-header-toast {
    @include mad-header-modal;
    line-height: none;
    font-size: 18px;
    font-weight: 700;
    padding: 0;
    min-height: auto;
    line-height:2;
    margin-bottom: 0.1em;
}
